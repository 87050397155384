<template>
  <div>
    <b-card title="MIT🚀">
      <b-card-text>Mediterranean Institute Of Tunisia</b-card-text>
    </b-card>
    <b-row>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          v-if="result[0] != undefined"
          class="font-weight-bold"
          style="background-color: #f9ccac;"
          color="warning"
          icon="AlertTriangleIcon"
          :statistic="`Total Etudiants : ${result[0].count_inscrit}`"
          :statistic-title="`Total payment :!
          ${result[0].count_inscrit} DT`"
          :chart-data="subscribersGained.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          v-if="result[1] != undefined"
          color="warning"
          class="font-weight-bold"
          icon="FileTextIcon"
          style="background-color:#fbefcc;"
          :statistic="`Total Enseignants : ${teacher[0].count_teacher}`"
          :chart-data="subscribersGained.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          v-if="result[2] != undefined"
          icon="DownloadIcon"
          color="warning"
          class="font-weight-bold"
          style="background-color:#fff2df;"
          :statistic="`Total payments : ${payments[2].total_payement_students} DT`"
          :statistic-title="`Total payment 2022
          ${result[2].total_price_ttc}`"
          :chart-data="subscribersGained.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
      >
        <statistic-card-with-area-chart
          v-if="result[3] != undefined"
          icon="TruckIcon"
          style="background-color:#9bf4d5;"
          class="font-weight-bold"
          color="success"
          :statistic="`Total rest a payer  : ${result[3].number}`"
          :statistic-title="`Total cette année:
          ${result[3].total_price_ttc}`"
          :chart-data="subscribersGained.series"
        />
      </b-col>
    </b-row>
    <!-- <b-row>

      <b-col
        lg="3"
        sm="6"
        @click="show('4')"
      >
        <statistic-card-with-area-chart
          v-if="result[4] != undefined"
          icon="CheckSquareIcon"
          style="background-color:#ddeedd"
          color="success"
          class="font-weight-bold"
          :statistic="`Colis livré : ${result[4].number}`"
          :statistic-title="`Prix
          colis
          livré :
          ${result[4].total_price_ttc}`"
          :chart-data="subscribersGained.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
        @click="show('6')"
      >
        <statistic-card-with-area-chart
          v-if="result[6] != undefined"
          icon="DownloadIcon"
          style="background-color:#e0ffcd;;"
          color="success"
          class="font-weight-bold"
          :statistic="`Colis livré payé : ${result[6].number}`"
          :statistic-title="`Prix
          colis
          livré payé :
          ${result[6].total_price_ttc}`"
          :chart-data="subscribersGained.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
        @click="show('5')"
      >
        <statistic-card-with-area-chart
          v-if="result[5] != undefined "
          icon="TruckIcon"
          color="danger"
          class="font-weight-bold"
          style="background-color:#ffebeb;"
          :statistic="`Colis retour au depot : ${result[5].number}`"
          :statistic-title="`Prix
          colis
          retour au depot :
          ${result[5].total_price_ttc}`"
          :chart-data="subscribersGained.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
        @click="show('7')"
      >
        <statistic-card-with-area-chart
          v-if="result[7] != undefined "
          icon="TruckIcon"
          color="danger"
          class="font-weight-bold"
          style="background-color:#f8b7ba;"
          :statistic="`Colis retour au fournisseur : ${result[7].number}`"
          :statistic-title="`Prix
          colis
          retour au fournisseur :
          ${result[7].total_price_ttc}`"
          :chart-data="subscribersGained.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
        @click="show('9')"
      >
        <statistic-card-with-area-chart
          v-if="result[9] != undefined "
          icon="TruckIcon"
          color="danger"
          class="font-weight-bold"
          style="background-color:#ffaaa5;"
          :statistic="`Colis retour au fournisseur payé: ${result[9].number}`"
          :statistic-title="`Prix
          colis
          retour au fournisseur payé:
          ${result[9].total_price_ttc}`"
          :chart-data="subscribersGained.series"
        />
      </b-col>
      <b-col
        lg="3"
        sm="6"
        @click="show('8')"
      >
        <statistic-card-with-area-chart
          v-if="result[8] != undefined"
          icon="TrendingUpIcon"
          style="background-color:#e7eaf6;"
          color="info"
          class="font-weight-bold"
          :statistic="`Colis en transfert : ${result[8].number}`"
          :statistic-title="`Prix
          colis
          en
          transfert :
          ${result[8].total_price_ttc}`"
          :chart-data="subscribersGained.series"
        />
      </b-col>
    </b-row>
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <div
      v-if="load === 'true'"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div> -->
    <!-- table -->
    <!-- <vue-good-table
      :columns="columns"
      :rows="transactions"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <span v-if="props.column.field === 'user'">
          <b-badge
            class="badge-glow"
            pill
            variant="success"
          >
            <feather-icon
              icon="UserIcon"
              class="mr-25"
            />
            <span> {{ props.row.user.username }}</span>
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'nb_delivered'">

          <feather-icon
            icon="TrendingUpIcon"
            class="mr-25"
            color="green"
          />
          <span> {{ props.row.nb_delivered }}</span>
        </span>
        <span v-else-if="props.column.field === 'nb_return'">

          <feather-icon
            icon="TrendingDownIcon"
            class="mr-25"
            color="red"
          />
          <span> {{ props.row.nb_return }}</span>
        </span>
        <span v-else-if="props.column.field === 'total'">

          <feather-icon
            icon="DollarSignIcon"
            class="mr-25"
            color="orange"
          />
          <span> {{ props.row.total }}</span>
        </span>
        <span v-else-if="props.column.field === 'created_at'">

          <feather-icon
            icon="CalendarIcon"
            class="mr-25"
            color="green"
          />
          <span> {{ formatFn(props.row.created_at) }}</span>
          <feather-icon
            icon="ClockIcon"
            class="ml-1"
            color="green"
          />
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table> -->

  </div>
</template>

<script>
import {
  // BBadge, BPagination,BFormSelect,
  BCard, BCardText,
  BCol, BRow,

} from 'bootstrap-vue'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'

// import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import { kFormatter } from '@core/utils/filter'
// import storeAuth from '@/store/store'

export default {
  components: {
    // VueGoodTable,
    BCard,
    BCardText,
    // BBadge,
    // BPagination,
    // BFormGroup,
    // BFormSelect,
    // BFormInput,
    // BSpinner,
    StatisticCardWithAreaChart,
    BCol,
    BRow,
  },
  data() {
    return {

      allStatus: [0, 1, 2, 3, 4, 5, 6, 7],
      result: [],
      teacher: [],
      load: 'false',
      pageLength: 10,
      dir: false,
      subscribersGained: {
        series: [
          {
            name: '',
            data: [28, 40, 36, 52, 38, 60, 55],
          },
        ],
        analyticsData: {
          subscribers: 92600,
        },
      },
      columns: [
        {
          label: 'Livreur',
          field: 'user',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher Livreur',
          },
        },
        {
          label: 'NB colis livré',
          field: 'nb_delivered',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher nb',
          },
        },
        {
          label: 'NB colis retour',
          field: 'nb_return',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher nb',
          },
        },
        {
          label: 'Total',
          field: 'total',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher total',
          },
        },

        {
          label: 'date | heure',
          field: 'created_at',
          formatFn: this.formatFn,
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher date',
          },
        },

      ],
      transactions: [],
      payments: [],
      searchTerm: '',
    }
  },

  created() {
    // this.getTransactions()
    this.getInscriptions()
    this.getTeachers()
    this.getPayments()
  },

  methods: {
    show(level) {
      this.$router.push({ name: 'order-list', params: { level } })
    },
    async getInscriptions() {
      const response = await axios.get('/api/inscriptions/count-inscrit/')
      this.result = response.data
    },
    async getTeachers() {
      const response = await axios.get('/api/authentication/count-teacher/')
      this.teacher = response.data
    },
    async getPayments() {
      const response = await axios.get('/api/payments/total-payment/')
      this.payments = response.data
    },

    kFormatter,
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')} | ${h}`
    },
    // async getTransactions() {
    //   this.load = 'true'
    //   await axios.get('api/transactions/', { params: { depot: storeAuth.state.user.depot } }).then(response => {
    //     this.transactions = response.data
    //     this.load = 'false'
    //   })
    // },

  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
